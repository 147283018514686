<template>
  <List :ticketType="1"></List>
</template>

<script>
import List from './list.vue'
export default {
  components: {List}
}
</script>
